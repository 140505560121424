<template>
  <modal
    class="modal-overlay theme"
    :name="name"
    height="auto"
    :scrollable="true"
    @before-open="beforeOpen"
    @before-close="beforeClose"
  >
    <ModalLayout :name="name">
      <h2 class="modal-title">Выберите одну капсулу</h2>
      <p class="modal-desc">
        Обнаружен тайник с&nbsp;инопланетными гермокапсулами!<br />В&nbsp;одной
        из&nbsp;них спрятано задание, которое позволит вам продвинуться вперед.
        Вам предстоит выбрать одну из&nbsp;капсул, полагаясь на&nbsp;опыт
        и&nbsp;интуицию. Удачи! Совет Галактики надеется, что вы&nbsp;сумеете
        найти и&nbsp;выполнить задание!
      </p>
      <div class="chests">
        <button
          v-for="(item, index) in chests"
          :key="index"
          :disabled="disabled"
          @click="open(index)"
          class="chest"
          :class="[item.state, item.color]"
        ></button>
      </div>
    </ModalLayout>
  </modal>
</template>

<script>
import ModalLayout from "@/layout/ModalLayout.vue";
import { OpenCell } from "@/api/Player";
import { mapActions } from "vuex";

export default {
  components: {
    ModalLayout,
  },
  data() {
    return {
      chests: [
        {
          state: "closed",
          color: "green",
        },
        {
          state: "closed",
          color: "lilac",
        },
        {
          state: "closed",
          color: "pink",
        },
      ],
      name: "chests",
      disabled: false,
      target: null,
      cellIndex: null,
    };
  },
  methods: {
    ...mapActions(["USER_FETCH"]),
    open(index) {
      this.disabled = true;

      // // симулируем открытие
      // setTimeout(() => {
      //   this.animationShake(index, "gold").then(() => {
      //     this.$modal.hide(this.name);
      //   });
      // }, 200);
      // return;

      OpenCell({ cellIndex: this.cellIndex })
        .then((resp) => {
          if (resp.data.hasQuest) {
            this.animationShake(index, "gold").then(() => {
              this.$modal.hide(this.name);
              this.$emit("openedQuest", {
                target: this.target,
                cellIndex: this.cellIndex,
                resp: resp.data,
              });
              this.USER_FETCH();
            });
          } else {
            this.animationShake(index, "opened").then(() => {
              this.$modal.hide(this.name);
              this.$emit("openedEmpty", {
                target: this.target,
                cellIndex: this.cellIndex,
              });
            });
          }
        })
        .catch(this.handleError)
        .finally(() => {
          this.disabled = false;
        });
    },
    animationShake(index, newState) {
      return new Promise((resolve) => {
        this.chests[index].state = "closed shake";
        setTimeout(() => {
          this.chests[index].state = newState;
          setTimeout(() => {
            resolve();
          }, 750);
        }, 800);
      });
    },
    beforeOpen(event) {
      this.reset();
      this.target = event.params.target;
      this.cellIndex = event.params.cellIndex;
      setTimeout(() => {
        document.getElementsByClassName("modal-close")[0].focus();
      }, 1000);
    },
    reset() {
      this.chests = [
        {
          state: "closed",
          color: "green",
        },
        {
          state: "closed",
          color: "lilac",
        },
        {
          state: "closed",
          color: "pink",
        },
      ];
      this.chests = this.chests.sort(() => 0.5 - Math.random());
      this.name = "chests";
      this.disabled = false;
      this.target = null;
      this.cellIndex = null;
    },
    beforeClose() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-content {
  // width: 810px;
  // height: 417px;
}
.chests {
  margin-bottom: -90px;
  margin-left: -50px;
  margin-right: -50px;
}
.chest {
  background-color: transparent;
  border: none;
  outline: none;
  width: 308px;
  height: 430px;
  background-image: url(../assets/img/pic2/capsule.png);
  background-repeat: no-repeat;
  margin-top: -50px;
  margin-left: -35px;
  margin-right: -35px;
  &.green {
    background-position: 0 0px;
    &.gold {
      background-position: -310px 0px;
    }
    &.opened {
      background-position: -631px 0px;
    }
  }
  &.lilac {
    background-position: 0 -440px;
    &.gold {
      background-position: -310px -440px;
    }
    &.opened {
      background-position: -630px -440px;
    }
  }
  &.pink {
    background-position: 0 -880px;
    &.gold {
      background-position: -310px -880px;
    }
    &.opened {
      background-position: -630px -880px;
    }
  }
  &.closed {
    // background-image: url(../assets/img/modal/pic/chest_closed.png);
    // background-size: cover;
  }
  // &.opened {
  //   background-image: url(../assets/img/modal/pic/chest_opened.png);
  //   background-size: cover;
  // }
  // &.gold {
  //   background-image: url(../assets/img/modal/pic/chest_gold.png);
  //   background-size: cover;
  // }
  &.shake {
    animation: shake 0.8s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 1px, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, -2px, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 3px, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, -1px, 0);
  }
}
</style>
